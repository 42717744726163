import { getProblemDetail, isHttpError, SanitizedError } from '@mri-platform/angular-error-handling';
import {
  convertServerSaveErrorToDto,
  isBreezeServerError,
  isBreezeServerSaveError,
  NgServerError
} from '@mri/breeze-entity';
import { EntityErrorFromServer } from 'breeze-client/src/entity-manager';
import { PascalCasedProperties } from 'type-fest';

export function sanitizeBreezeServerError(err: unknown) {
  if (!isBreezeServerError(err)) return undefined;

  const { ngOriginalError, problem } = getBreezeServerErrorDetails(err);
  const reason = err.httpResponse.saveContext == null ? 'fetch' : 'save';
  return new SanitizedError({
    message: SanitizedError.getDefaultMessage(reason, problem),
    isError: isHttpError(err.httpResponse.status, reason === 'fetch'),
    ngOriginalError,
    detail: problem
  });
}

function getBreezeServerErrorDetails(err: NgServerError) {
  const problem = getProblemDetail(err.httpResponse.response);
  if (isBreezeServerSaveError(err)) {
    const errorDto = convertServerSaveErrorToDto(
      err,
      problem?.EntityErrors as PascalCasedProperties<EntityErrorFromServer>[] | undefined
    );
    let problemDto = problem;
    if (problem?.EntityErrors) {
      const { EntityErrors: _, ...restOfProblem } = problem;
      problemDto = { ...restOfProblem, entityErrors: errorDto.entityErrors };
    }
    return { ngOriginalError: errorDto, problem: problemDto };
  } else {
    return { ngOriginalError: err, problem };
  }
}
