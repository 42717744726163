import { Provider } from '@angular/core';
import { SwitchClientFunc, SWITCH_CLIENT_FUNC } from '@mri-platform/shared/core';
import { UnitOfWorkService } from '@mri/breeze-entity';

export function clearEntityCache(uow: UnitOfWorkService): SwitchClientFunc {
  return () => {
    uow.reset();
  };
}

export const ClearEntityCacheProvider: Provider = {
  provide: SWITCH_CLIENT_FUNC,
  useFactory: clearEntityCache,
  multi: true,
  deps: [UnitOfWorkService]
};
