import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainMenuItemService, SharedShellModule } from '@mri-platform/shared/shell';
import { HomePageComponent } from './containers/home-page.component';
import { ShellComponent } from './shell/shell.component';
import { AppMainMenuItemService } from './ui-services';

@NgModule({
  imports: [CommonModule, RouterModule, SharedShellModule],
  providers: [{ provide: MainMenuItemService, useClass: AppMainMenuItemService }],
  declarations: [ShellComponent, HomePageComponent]
})
export class DSGShellModule {}

// make public (ie available outside of this library)...
export { ShellComponent, HomePageComponent };
