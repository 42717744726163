import { Injectable } from '@angular/core';
import { RegisteredEntityMetaDataService } from '@mri-platform/shared/core';
import { MetadataStore } from 'breeze-client';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreezeRegisteredEntityMetaDataService implements RegisteredEntityMetaDataService {
  private registeredEntityNames = new BehaviorSubject<string[]>([]);
  registeredEntityNames$ = this.registeredEntityNames.asObservable();

  constructor(metadataStore: MetadataStore) {
    const entityNames = metadataStore.getEntityTypes().map(et => et.shortName);
    this.registeredEntityNames.next(entityNames);
  }
}
