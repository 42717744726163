export type KeyGenerator<T extends Object = Object> = (idx: number, data: T) => Object;

// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid/2117523#2117523
// to-do:
// crypto method not supported by jest will use below method https://github.com/jsdom/jsdom/issues/1612
// 6412 - create message card
const generateUUId = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line no-bitwise
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });


const guidStringFor = (fieldName: string) => () => ({ [fieldName]: generateUUId() });
const guidStringId: KeyGenerator = guidStringFor('id');
const numberFromPositionFor = (fieldName: string) => (idx: number) => ({ [fieldName]: idx + 1 });
const numberIdFromPosition: KeyGenerator = numberFromPositionFor('id');
const stringFromPositionFor = (fieldName: string) => (idx: number) => ({ [fieldName]: `${idx + 1}` });
const stringIdFromPosition: KeyGenerator = stringFromPositionFor('id');

export const KeyGen = {
  guidStringFor,
  guidStringId,
  numberIdFromPosition,
  numberFromPositionFor,
  stringIdFromPosition,
  stringFromPositionFor
};
