import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
  HttpClientBlobErrorInterceptor,
  HttpClientErrorEnrichmentInterceptor,
  sanitizeHttpClientProblemDetails
} from '@mri-platform/angular-error-handling';
import { AppRoutes } from '@mri-platform/dsg/core';
import { EntityStateModule } from '@mri-platform/dsg/entity-state';
import { DSGShellModule, HomePageComponent, ShellComponent } from '@mri-platform/dsg/shell';
import {
  AppBreezeIntegrationModule,
  sanitizeBreezeClientSaveError,
  sanitizeBreezeServerError
} from '@mri-platform/shared/app-breeze-integration';
import {
  ApiVersionInterceptor,
  AppCoreIntegrationModule,
  AppUserPermissionService,
  ImpersonateClientSwitchService
} from '@mri-platform/shared/app-core-integration';
import { AppInsightsIntegrationModule } from '@mri-platform/shared/app-insights-integration';
import { NavigationModule } from '@mri-platform/shared/app-ngrx-integration';
import { ClientSwitchService, SharedCoreModule, environment } from '@mri-platform/shared/core';
import { UnhandledErrorPageComponent } from '@mri-platform/shared/shell';
import { AuthGuard, UserInfoService, UserPermissionService } from '@mri/angular-wfe-proxy-oidc';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppComponent } from './app.component';
import { AppUserInfoService, DashboardPageViewTelemetryInitializer, ignoreIframeError } from './integration';
import { ROOT_REDUCERS } from './reducers';
import { testingProviders } from './testing';
import { TELEMETRY_INITIALIZER } from '@mri-platform/angular-app-insights';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: ShellComponent,
    children: [
      {
        path: 'about',
        outlet: 'modal',
        loadChildren: () => import('@mri-platform/dsg/about').then(module => module.AboutModule)
      },
      {
        path: 'onboard-config',
        loadChildren: () =>
          import('@mri-platform/dsg/client-onboard-config').then(module => module.ClientOnboardConfigModule)
      },
      AppRoutes.Home.applyTo({ component: HomePageComponent }),
      AppRoutes.Error.applyTo({ component: UnhandledErrorPageComponent }),
      {
        path: 'dashboards',
        loadChildren: () => import('@mri-platform/dsg/dashboards').then(module => module.DashboardsModule)
      },
      {
        path: 'switch-client',
        outlet: 'drawer',
        loadChildren: () => import('@mri-platform/dsg/client-switch').then(module => module.ClientSwitchModule)
      },
      {
        path: 'user-settings',
        loadChildren: () => import('@mri-platform/dsg/user-settings').then(module => module.UserSettingsModule)
      },
      {
        path: 'dashboard-management',
        loadChildren: () =>
          import('@mri-platform/dsg/dashboard-management').then(module => module.DashboardManagementModule)
      },
      {
        path: 'reconciliation',
        loadChildren: () => import('@mri-platform/dsg/reconciliation').then(module => module.ReconciliationModule)
      },
      { path: '', redirectTo: AppRoutes.Home.path, pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
      // enableTracing: !environment.production
    }),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    AppInsightsIntegrationModule,
    AppCoreIntegrationModule.forRoot({
      errorHandling: {
        sanitizers: [sanitizeHttpClientProblemDetails, sanitizeBreezeServerError, sanitizeBreezeClientSaveError]
      },
      errorLogging: { selectors: [ignoreIframeError] }
    }),
    AppBreezeIntegrationModule.forRoot(),
    NavigationModule,
    DSGShellModule,
    BrowserAnimationsModule,
    EntityStateModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    SharedCoreModule.forRoot({ useFeatureFlags: true }).providers ?? [],
    testingProviders,
    { provide: ClientSwitchService, useClass: ImpersonateClientSwitchService },
    { provide: UserPermissionService, useClass: AppUserPermissionService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientErrorEnrichmentInterceptor, multi: true },
    // critical: interceptors that handle errors must appear AFTER HttpClientErrorEnrichmentInterceptor
    { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientBlobErrorInterceptor, multi: true },
    { provide: UserInfoService, useClass: AppUserInfoService },
    { provide: TELEMETRY_INITIALIZER, useClass: DashboardPageViewTelemetryInitializer, multi: true }
  ]
})
export class AppModule {}
