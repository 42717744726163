import { FactoryProvider } from '@angular/core';
import { BreezeLibraryConfig } from '@mri/breeze-entity';
import { ApiConfig } from '@mri-platform/shared/core';

export const DefaultBreezeLibraryConfigProvider: FactoryProvider = {
  provide: BreezeLibraryConfig,
  useFactory: (apiConfig: ApiConfig): BreezeLibraryConfig => ({
    serviceName: `${apiConfig.url}/breeze`
  }),
  deps: [ApiConfig]
};
