import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BREEZE_GET_REQUEST_FUNC,
  BREEZE_SAVE_REQUEST_FUNC,
  Db,
  DELETE_REQUEST_FUNC,
  GET_REQUEST_FUNC,
  InMemoryDbService,
  PARSE_REQUEST_URL_FUNC,
  ParsedRequestUrl,
  POST_REQUEST_FUNC,
  PUT_REQUEST_FUNC,
  RequestInfo,
  RequestInfoUtilities,
  TryHandleRequest,
  TryParseRequestUrl
} from './interfaces';

const normalizeRequestInfo = (reqInfo: RequestInfo): RequestInfo => {
  const { collectionName } = reqInfo;
  return {
    ...reqInfo,
    collectionName: collectionName ? collectionName.toLowerCase() : collectionName
  };
};

const composeHandlers = (handlers: TryHandleRequest[] | undefined) => {
  if (!handlers) {
    return () => undefined;
  }

  return (reqInfo: RequestInfo): Observable<any> | undefined => {
    const normalizedInfo = normalizeRequestInfo(reqInfo);
    for (const handler of handlers) {
      const response = handler(normalizedInfo);
      if (response) return response;
    }
    return undefined; // let the default implementation in BackendService handle all others
  };
}

export const INMEMORY_DB_COLLECTIONS_TOKEN = new InjectionToken<Db>('INMEMORY_DB_COLLECTIONS');

export interface ComposableInMemoryDbService {
  breezeGet(reqInfo: RequestInfo): Observable<any> | undefined;
  breezeSave(reqInfo: RequestInfo): Observable<any> | undefined;
  delete(reqInfo: RequestInfo): Observable<any> | undefined;
  get(reqInfo: RequestInfo): Observable<any> | undefined;
  parseRequestUrl(url: string, utils: RequestInfoUtilities): ParsedRequestUrl;
  post(reqInfo: RequestInfo): Observable<any> | undefined;
  put(reqInfo: RequestInfo): Observable<any> | undefined;
}


@Injectable()
export class ComposableInMemoryDbService implements InMemoryDbService {

  private db: Db = {};
  private readonly urlParsers: TryParseRequestUrl[];

  constructor(
    @Optional() @Inject(BREEZE_GET_REQUEST_FUNC) breezeGetRequests: TryHandleRequest[],
    @Optional() @Inject(BREEZE_SAVE_REQUEST_FUNC) breezeSaveRequests: TryHandleRequest[],
    @Optional() @Inject(DELETE_REQUEST_FUNC) deleteRequests: TryHandleRequest[],
    @Optional() @Inject(GET_REQUEST_FUNC) getRequests: TryHandleRequest[],
    @Optional() @Inject(POST_REQUEST_FUNC) postRequests: TryHandleRequest[],
    @Optional() @Inject(PUT_REQUEST_FUNC) putRequests: TryHandleRequest[],
    @Optional() @Inject(PARSE_REQUEST_URL_FUNC) urlParsers: TryParseRequestUrl[],
    @Optional() @Inject(INMEMORY_DB_COLLECTIONS_TOKEN) collections: Db[]) {
    (collections || []).forEach(db => this.registerCollections(db));

    this.urlParsers = urlParsers ?? [];
    this.breezeGet = composeHandlers(breezeGetRequests);
    this.breezeSave = composeHandlers(breezeSaveRequests);
    this.get = composeHandlers(getRequests);
    this.delete = composeHandlers(deleteRequests);
    this.post = composeHandlers(postRequests);
    this.put = composeHandlers(putRequests);
  }

  createDb() {
    return this.db;
  }

  parseRequestUrl(url: string, utils: RequestInfoUtilities): ParsedRequestUrl {
    for (const parser of this.urlParsers) {
      const parsed = parser(url, utils);
      if (parsed) return parsed;
    }
    return utils.parseRequestUrl(url);
  }

  private registerCollections(collections: Db) {
    Object.assign(this.db, collections);
  }
}
