import { SanitizedError } from '@mri-platform/angular-error-handling';
import { convertClientSaveErrorToDto, isBreezeClientSaveError } from '@mri/breeze-entity';

export function sanitizeBreezeClientSaveError(err: unknown) {
  if (!isBreezeClientSaveError(err)) return undefined;

  return new SanitizedError({
    message: SanitizedError.getDefaultMessage('save'),
    isError: false,
    ngOriginalError: convertClientSaveErrorToDto(err)
  });
}
