import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClaimsAuthzService } from '@mri-platform/resource-authz';
import { RegisteredEntityMetaDataService } from '@mri-platform/shared/core';
import { BreezeEntityModule, BreezeEntityModuleConfig } from '@mri/breeze-entity';
import { BreezeEntityClaimsAuthzService } from './breeze-entity-claims-authz.service';
import { BreezeRegisteredEntityMetaDataService } from './breeze-registered-entity-meta-data.service';
import { ClearEntityCacheProvider } from './clear-entity-cache';
import { DefaultBreezeLibraryConfigProvider } from './default-breeze-library-config.provider';

@NgModule({
  imports: [CommonModule, BreezeEntityModule.forRoot({ propertyOptions: { requiredCssClass: 'k-required' } })]
})
export class AppBreezeIntegrationModule {
  static forRoot(_config: BreezeEntityModuleConfig = {}): ModuleWithProviders<AppBreezeIntegrationModule> {
    return {
      ngModule: AppBreezeIntegrationModule,
      providers: [
        // BreezeEntityModule.forRoot(config ?? {}).providers ?? [],
        DefaultBreezeLibraryConfigProvider,
        ClearEntityCacheProvider,
        {
          provide: RegisteredEntityMetaDataService,
          useExisting: BreezeRegisteredEntityMetaDataService
        },
        { provide: ClaimsAuthzService, useExisting: BreezeEntityClaimsAuthzService }
      ]
    };
  }
}

// these types will be made public to the library
export { BreezeRegisteredEntityMetaDataService };
