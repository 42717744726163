import { Injectable } from '@angular/core';
import {
  AuthzContext,
  ClaimsAuthzCtxParserService,
  ClaimsAuthzService,
  getAuthzContextAction,
  getAuthzContextResourceTypes,
  UserPermission,
  WhiteListDataService
} from '@mri-platform/resource-authz';
import { isNonNullable } from '@mri-platform/shared/core';
import { MetadataStore } from 'breeze-client';
import { EntityCtor } from './entity-constructor';

@Injectable({ providedIn: 'root' })
export class BreezeEntityClaimsAuthzService extends ClaimsAuthzService {
  constructor(
    private metadataStore: MetadataStore,
    claimsAuthzCtxParserService: ClaimsAuthzCtxParserService,
    whiteListDataService: WhiteListDataService
  ) {
    super(claimsAuthzCtxParserService, whiteListDataService);
  }

  checkAccess(authzContext: AuthzContext | AuthzContext[], userPermissions: UserPermission[]) {
    // todo: change the logic when checkAccess will only take AuthzContext
    const resolvedAuthzContext = this.resolveAuthzContexts(authzContext);
    if (resolvedAuthzContext.length > 1) {
      const all = super.checkAccessMany(resolvedAuthzContext, userPermissions);
      return super.mergeAccessCheckResults(all);
    }
    return super.checkAccess(resolvedAuthzContext[0], userPermissions);
  }

  private resolveAuthzContexts(authzContext: AuthzContext | AuthzContext[]) {
    const contexts = Array.isArray(authzContext) ? authzContext : [authzContext];
    return contexts.flatMap(c => this.resolveAuthzContext(c));
  }

  private resolveAuthzContext(authzContext: AuthzContext) {
    if (!authzContext.requiresResolve) return authzContext;

    const entityNames = getAuthzContextResourceTypes(authzContext);
    // todo: support resolving more than one action type
    const action = getAuthzContextAction(authzContext);
    if (Array.isArray(action)) {
      throw new Error('resolving multiple actions not currently implemented');
    }

    return entityNames
      .map(e => (this.metadataStore.getAsEntityType(e, false).getCtor() as EntityCtor).authorization)
      .filter(isNonNullable)
      .flatMap(e => e[action]);
  }
}
