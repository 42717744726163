import { Injectable } from '@angular/core';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { PageViewTelemetryEnrichmentInitializer } from '@mri-platform/angular-app-insights';
import { AppRoutes } from '@mri-platform/dsg/core';
import { ReportInfo } from '@mri-platform/dsg/entity-state';

interface RouteData {
  entity: ReportInfo;
}

@Injectable()
export class DashboardPageViewTelemetryInitializer extends PageViewTelemetryEnrichmentInitializer {
  override get requiredRoutePath() {
    return AppRoutes.Dashboards.Detail.rootPath;
  }

  protected getData(_item: ITelemetryItem) {
    const { entity } = this.currentRoute.data as RouteData;
    return {
      workspaceLabel: entity.workspaceLabel
    };
  }
}
