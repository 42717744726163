import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorPolicyService, muteError } from '@mri-platform/angular-error-handling';
import { DefaultUserInfoService, UserAttributes, UserInfoService } from '@mri/angular-wfe-proxy-oidc';
import { STATUS } from 'angular-breeze-in-memory-web-api';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AppUserInfoService implements UserInfoService {
  constructor(
    private defaultUserInfoService: DefaultUserInfoService,
    private router: Router,
    private errorpolicy: ErrorPolicyService
  ) {}

  get(): Observable<UserAttributes> {
    const userAttributes$ = this.defaultUserInfoService.get<UserAttributes>();
    return userAttributes$.pipe(
      catchError(err => {
        this.errorpolicy.log(err, muteError);
        if (err.status === STATUS.FORBIDDEN) {
          this.router.navigateByUrl('unauthorized');
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }
}
